import Vue from 'vue'

const ClassifyService = {
  all (param) {
    return Vue.http.get('classify/index', param)
  },

  delete (id) {
    return Vue.http.delete('classify/delete', { id: id })
  },

  create (store = null) {
    const endpoint = 'classify/create'

    if (store === null) {
      return Vue.http.get(endpoint)
    }

    return Vue.http.post(endpoint, store)
  },

  edit (id, user = null) {
    if (user === null) {
      return Vue.http.get('classify/update', { id: id })
    }

    return Vue.http.put('classify/update', user, { id: id })
  },
  changeStatus (item) {
    return Vue.http.put('classify/change-status', item)
  }
}

export default ClassifyService
