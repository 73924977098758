<template>
    <div class="box">
        <div class="box-header">
        <h4>{{ pageTitle }}</h4>
        </div>
        <classify-form v-if="classify"
          :submit-text="pageTitle"
          :classify="classify"
          :is-edit="true"
          @on-submit="createClassify">
        </classify-form>
        <placeholder-form v-else></placeholder-form>
    </div>
</template>

<script>
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'
import classifyForm from './components/_EditForm'
import flatry from '@admin/utils/flatry'
import ClassifyService from '@admin/services/ClassifyService'

export default {
  components: { classifyForm, PlaceholderForm },
  data () {
    return {
      pageTitle: '新建分类',
      classify: {
        classifyName: '',
        classifyCover: '',
        isGlobal: false,
        branchShopIds: [],
        shops: [],
        shopsIds: []
      }
    }
  },
  async created () {
    const { data } = await flatry(ClassifyService.create())
    if (data) {
      this.classify.shops = data.data
    }
  },

  methods: {
    async createClassify (classify, success, callback) {
      const { data } = await flatry(ClassifyService.create(classify))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/classify/update', query: { id: data.data } })
        success()
      }

      callback()
    }
  }
}
</script>

<style scoped>

</style>
