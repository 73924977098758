<template>
  <el-form
    v-if="formModel"
    :rules="formRules"
    :model="formModel"
    ref="formModel"
    label-width="100px"
    label-suffix="："
    @submit.native.stop.prevent="handleFormSubmit('formModel')"
  >
    <el-form-item
      label="分类名称"
      prop="classifyName"
    >
      <el-col :md="9">
        <el-input
          type="text"
          v-model.trim="formModel.classifyName"
        ></el-input>
      </el-col>
    </el-form-item>

    <el-form-item
      label="全局分类"
      prop="isGlobal"
    >
      <el-switch
        :active-text="formModel.isGlobal == 1?'是':'否'"
        v-model="formModel.isGlobal"
        @change="checkOutGlobal"
      >
      </el-switch>
    </el-form-item>

    <el-form-item label="顺序位置">
      <el-input
        style="width:200px;"
        type="number"
        v-model="formModel.classifyPosition"
        placeholder=""
      ></el-input>
    </el-form-item>

    <el-form-item label="开启店铺">
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
      >全选</el-checkbox>
      <el-checkbox-group
        v-model="classify.branchShopIds"
        @change="handleCheckedCitiesChange"
      >
        <el-checkbox
          v-for="(item,index) in classify.shops"
          :key="index"
          :label="item.id"
        >{{item.name}}</el-checkbox>
      </el-checkbox-group>
    </el-form-item>

    <el-form-item
      label="分类图片"
      prop="classifyCover"
    >
      <uploader
        :avatar="formModel.classifyCover"
        @on-upload-success="handleUploadSuccess"
      ></uploader>
    </el-form-item>

    <el-form-item>
      <el-button
        size="medium"
        type="primary"
        native-type="submit"
        :loading="submitLoading"
      >{{ submitText }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import Uploader from '@admin/components/uploaders/Uploader'

export default {
  name: 'ClassifyForm',
  components: { Uploader },
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    classify: {
      type: Object
    }
  },
  data () {
    return {
      isIndeterminate: false,
      checkAll: false,
      isGlobal: false,
      checkList: [],
      submitLoading: false,
      formRules: {
        classifyName: [
          {
            required: true,
            message: '请输入分类名称',
            trigger: 'blur'
          }
        ],
        classifyCover: [
          {
            required: true,
            message: '请上传分类图片',
            trigger: 'blur'
          }
        ]
      },
      formModel: null
    }
  },
  async mounted () {
    this.formModel = Object.assign(this.classify)
    if (
      this.classify.shopsIds.length > 0 &&
            this.classify.shopsIds.length === this.classify.branchShopIds.length
    ) {
      this.checkAll = true
    }
  },
  methods: {
    checkOutGlobal (val) {
      this.formModel.isGlobal = val
    },
    handleCheckAllChange (val) {
      if (val) {
        let arr = []
        this.classify.shops.forEach(element => {
          arr.push(element.id)
        })
        this.classify.branchShopIds = arr
      } else {
        this.classify.branchShopIds = []
      }
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange (value) {
      this.checkList = value
      let checkedCount = value.length
      this.checkAll = checkedCount === this.classify.shops.length
      this.isIndeterminate =
                checkedCount > 0 && checkedCount < this.classify.shops.length
    },
    handleUploadSuccess (avatarUrl) {
      this.formModel.classifyCover = avatarUrl
    },
    handleFormSubmit (formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return false
        }

        this.submitLoading = true

        this.$emit(
          'on-submit',
          this.formModel,
          () => {
            this.$refs[formName].clearValidate()
          },
          () => {
            this.submitLoading = false
          }
        )
      })
    }
  }
}
</script>

<style scoped>
</style>
